import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
// import { list } from "../features/paymentLog/paymentLogSlice";
import { list } from "../features/userSubscription/userSubscriptionSlice";
import { Tag } from "antd";

const UserSubscription = () => {

  // const [searchQuery, setSearchQuery] = useState('');

  // const handleInputChange = (event) => {
  //   setSearchQuery(event.target.value);
  // };

  const dispatch = useDispatch();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 1000,
    key: '',
    status: ''
  });

  useEffect(() => {
    dispatch(list(pagination));
  }, [dispatch, pagination]);


  const { userSubscriptions, total, isLoading } = useSelector((state) => state.userSubscriptions);

  const handleTableChange = (pagination) => {
    dispatch(list(pagination));
    setPagination({
      ...pagination,
      current: pagination.current,
      pageSize: pagination.pageSize,
    });
  };

  useEffect(() => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      total: total,
    }));
  }, [total]);

  const columns = [
    {
      title: 'Date',
      render: (record) => {
        const date = new Date(record.createdAt);
        const formattedDate = date.toLocaleDateString('en-US', {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        });
        const formattedTime = date.toLocaleTimeString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
        });
        return `${formattedDate}, ${formattedTime}`;
      },
      key: 'createdAt',
    },
    {
      title: "User",
      key: "user_id",
      render: (record) => {
        return (
          <div>
            <p>{record.user_id.first_name} {record.user_id.last_name}</p>
          </div>
        );
      }
    },
    {
      title: "Type",
      dataIndex: "payment_type",
    },
    {
      title: "Product Id",
      dataIndex: "product_id",
    },
    {
      title: "Start Date",
      render: (record) => {
        const date = new Date(record.start_date);
        const formattedDate = date.toLocaleDateString('en-US', {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        });
        const formattedTime = date.toLocaleTimeString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
        });
        return `${formattedDate}, ${formattedTime}`;
      },      
    },
    {
      title: "End Date",
      render: (record) => {
        const date = new Date(record.end_date);
        const formattedDate = date.toLocaleDateString('en-US', {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        });
        const formattedTime = date.toLocaleTimeString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
        });
        return `${formattedDate}, ${formattedTime}`;
      },      
    },
    {
      title: "Transaction_id",
      dataIndex: "transaction_id",
    },
    {
      title: "Currency",
      dataIndex: "currency",
    },
    {
      title: "Amount",
      dataIndex: "unit_amount",
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => {

        if (!status) {
          return <Tag color="default"></Tag>; // Neutral color for missing status
        }

        const color = status === 'ACTIVE' ? 'green' : 'red';
        return (
          <Tag color={color}>
            {status}
          </Tag>
        );
      },
    },
  ];

  // Handle key down events on search
  // const handleKeyDown = (event) => {
  //   if (event.key === 'Enter') {
  //     setPagination({
  //       ...pagination,
  //       current: 1,
  //       key: searchQuery,
  //     });
  //   }
  // };

  return (
    <div>
      <div className="row-space-between">
        <h3 className="mb-4 title">User Subscriptions</h3>
        <div className="my-row">
          <div className="mr-5">
            {/* <IoSearchOutline className="fs-4" /> */}
            {/* <Input placeholder="Search users"
              value={searchQuery}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              prefix={<IoSearchOutline className="fs-4" />}
            /> */}
          </div>
        </div>
      </div>
      <p> {isLoading} </p>
      <div>
        <Table
          columns={columns}
          dataSource={userSubscriptions}
          rowKey={(record) => record._id}
          onChange={handleTableChange}
          pagination={pagination}
          loading={isLoading}
        />
      </div>
    </div>
  );
};

export default UserSubscription;
