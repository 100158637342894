import axios from 'axios';
import { base_url } from "./baseUrl";

const axiosInstance = axios.create({
    baseURL: base_url,
});

axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("token");
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Add a response interceptor to handle 401 errors
axiosInstance.interceptors.response.use(
    (response) => {
        // Return response if it's successful
        return response;
    },
    (error) => {
        // Check if the response status is 401 (Unauthorized)
        if (error.response && error.response.status === 401) {
            // Remove the token from localStorage
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            // Redirect to the login page
            window.location.href = '/';
        }
        // Reject the promise for other errors
        return Promise.reject(error);
    }
);


export default axiosInstance;


