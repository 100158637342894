import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import Resetpassword from "./pages/Resetpassword";
import Forgotpassword from "./pages/Forgotpassword";
import MainLayout from "./components/MainLayout";
import Setting from "./pages/Setting";
import Customers from "./pages/Customers"
import Profile from "./pages/Profile";
import Subscriptions from "./pages/subscriptons";
import { ConfigProvider } from 'antd';
import CreateSubscription from "./pages/subscriptons/create";
import EditSubscription from "./pages/subscriptons/edit";
import ReportUser from "./pages/ReportUser";
import DiamondUser from "./pages/DiamondUser";
import Notification from './pages/Notification';
import NotificationLog from './pages/NotificationLog';
import Page from "./pages/Page";
import PaymentLog from "./pages/PaymentLog";
import UserSubscription from "./pages/UserSubscription";

function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#179296',
          borderRadius: 2,
        },
        components: {
          Menu: {
            itemColor: 'white',
            itemSelectedBg: 'white',
            itemSelectedColor: 'black',
            BgColor: '#179296',
          },
        }
      }}
    >
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/reset-password" element={<Resetpassword />} />
          <Route path="/forgot-password" element={<Forgotpassword />} />
          <Route path="/page/:type" element={<Page />} />
          <Route path="/admin" element={<MainLayout />}>
            <Route index element={<Dashboard />} />
            <Route path="setting" element={<Setting />} />
            <Route path="profile/:id" element={<Profile />} />
            <Route path="customers" element={<Customers />} />
            <Route path="subscription" element={<Subscriptions />} />
            <Route path="subscription/create" element={<CreateSubscription />} />
            <Route path="subscription/:id" element={<EditSubscription />} />
            <Route path="report-user/" element={<ReportUser />} />
            <Route path="diamond-user" element={<DiamondUser />} />
            <Route path="send-notification" element={<Notification />} />
            <Route path="notification-log" element={<NotificationLog />} />
            <Route path="subscription-logs" element={<PaymentLog />} />
            <Route path="user-subscriptions" element={<UserSubscription />} />            
          </Route>
        </Routes>
      </Router>
    </ConfigProvider>
  );
}
export default App;